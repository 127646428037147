import { InjectionToken } from '@angular/core';

// referrals-62816f8668 will be replaced during build step,
// it is used by sentry to match sourcemaps

export const AppRevisionToken = new InjectionToken('APP_REVISION');
export const AppRevisionProvider = {
  provide: AppRevisionToken,
  useValue: `referrals-62816f8668`,
};
