import { CommonModule } from '@angular/common';
import {
  Inject,
  Injectable,
  NgModule,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { Observable } from 'rxjs';

import { AnalyticsClient, AnalyticsSegmentClient, RtAnalyticsModule } from '@recruitee/analytics';
import {
  AccountBase,
  APP_CONFIG,
  COMPANY_ID,
  DetachedRouterOutlet,
  RtcErrorHandlerModule,
} from '@recruitee/common';
import { CountryDataModule, injectCountryList } from '@recruitee/country-data';
import {
  AuthService,
  HttpApiErrorHandlerService,
  HttpApiService,
  HttpRequestData,
  RtHttpModule,
} from '@recruitee/http';
import { I18nModule, LANGUAGE_CONFIG, LOCALE } from '@recruitee/i18n';
import { IntegrationEventActions } from '@recruitee/integrations';
import { IntegrationKinds } from '@recruitee/integrations-types';
import {
  IconsContainerComponent,
  PHONE_INPUT_DATA,
  protectedControlsRootProviders,
  RtPlatform,
  UIRootProviders,
} from '@recruitee/user-interface';
import {
  IntegrationsMailboxLinkInsertedActionToken,
  ManageSchedulerLinkDialogComponentToken,
  RtWysiwygModule,
  WysiwygUploader,
} from '@recruitee/wysiwyg';

import { environment } from '../environments/environment';
import { authService } from '../main-deps';
import { AppComponent } from './app.component';
import { AppRevisionProvider, AppRevisionToken } from './app.revision';
import { appConfig, languageConfig } from './config';
import { ReferralsRequestData } from './config/referrals-request-data';
import { CoreModule } from './core/core.module';
import { ReferralsHttpApiErrorHandlerService } from './core/http-api-error-handler.service';
import { MainViewModule } from './core/main-view/main-view.module';
import { ApplicationsDataModule } from './features/applications/data/applications-data.module';
import { ConditionsDataModule } from './features/conditions/data/conditions-data.module';
import { CoreDataModule } from './features/core/data/core-data.module';
import { FeatureDiscoveryDataModule } from './features/feature-discovery/data/feature-discovery-data.module';
import { MainViewDataModule } from './features/main-view/data/main-view-data.module';
import { OfferAssignmentsDataModule } from './features/offer-assignments/data/offer-assignments-data.module';
import { ProfileDataModule } from './features/profile/data/profile-data.module';
import { ProgramDataModule } from './features/program/data/program-data.module';
import { ReferrerDataModule } from './features/referrer/data/referrer-data.module';
import { TermsDataModule } from './features/terms/data/terms-data.module';
import { RoutingModule } from './routing/routing.module';
import { SharedModule } from './shared/shared.module';

@Injectable()
export class RtrWysiwygUploader implements WysiwygUploader {
  constructor(private httpApi: HttpApiService) {}

  public upload(file: File): Observable<{ fileUrl: string }> {
    const data = new FormData();
    data.append('upload[file]', file);
    return this.httpApi.post('/uploads', null, data);
  }
}

@Injectable()
export class Account extends AccountBase {}

@NgModule({
  imports: [
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MainViewModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RoutingModule,
    CoreModule,
    SharedModule,
    RtAnalyticsModule.forRoot({
      disable: !environment.analytics?.isEnabled,
      segmentKey: environment.analytics?.key || '',
      trackRouteChange: true,
    }),
    I18nModule.forPhraseApp({
      phraseAppProjectId: environment.i18n.phraseAppProjectId,
      editorEnabled: environment.i18n.editorEnabled,
    }),
    RtcErrorHandlerModule.forRoot(
      {
        enable: environment.angularProdMode,
        dsn: environment.sentry_dsn,
        revisionToken: AppRevisionToken,
      },
      Account,
    ),
    RtWysiwygModule.forRoot({
      provide: WysiwygUploader,
      useClass: RtrWysiwygUploader,
    }),
    EffectsModule.forRoot([]),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      },
    ),
    RtHttpModule.forRoot({
      provide: HttpRequestData,
      useClass: ReferralsRequestData,
    }),
    ProgramDataModule,
    OfferAssignmentsDataModule,
    ApplicationsDataModule,
    ConditionsDataModule,
    FeatureDiscoveryDataModule,
    ProfileDataModule,
    TermsDataModule,
    ReferrerDataModule,
    CoreDataModule,
    MainViewDataModule,
    CountryDataModule,
    IconsContainerComponent,
    DetachedRouterOutlet,
  ],
  declarations: [AppComponent],
  providers: [
    Account,
    AppRevisionProvider,
    { provide: COMPANY_ID, useValue: '' },
    { provide: LOCALE, useValue: languageConfig.defaultLanguage },
    { provide: APP_CONFIG, useValue: appConfig },
    { provide: LANGUAGE_CONFIG, useValue: languageConfig },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: <RecaptchaSettings>{ siteKey: appConfig.recaptchaSiteKey, size: 'invisible' },
    },
    {
      provide: HttpApiErrorHandlerService,
      useClass: ReferralsHttpApiErrorHandlerService,
      deps: [AuthService, [Inject, COMPANY_ID]],
    },
    {
      provide: AuthService,
      useValue: authService,
    },
    {
      provide: ManageSchedulerLinkDialogComponentToken,
      useValue: null,
    },
    {
      provide: IntegrationsMailboxLinkInsertedActionToken,
      useValue: IntegrationEventActions.MailboxLinkInsertedEvent({
        payload: { context: { integrationKind: IntegrationKinds.KUNUNU } },
      }),
    },
    {
      provide: PHONE_INPUT_DATA,
      useFactory: injectCountryList,
    },
    { provide: AnalyticsClient, useClass: AnalyticsSegmentClient },
    RtPlatform,
    UIRootProviders(),
    protectedControlsRootProviders(),
    provideExperimentalZonelessChangeDetection(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
